import { roundNumber } from "../../utils/helper";

const useUnitValueConversion = () => {
  const convertFtValueToMeter = (val: number) => {
    return roundNumber(val * 0.3048, 14);
  };

  return { convertFtValueToMeter };
};

export default useUnitValueConversion;
