import { GridColumns } from "../../types/grid/column";

import * as attribute from "../../constants/attributes";
import * as constants from "../../constants/grid";
import { GRID_COLUMN_FORMATTING_RULES } from "../../constants/grid/displayFormat";

import { formattedNumber } from "../helper";
import {
  getDateFilterOperators,
  getNumberFilterOperators,
} from "./filterOperators";

//Note: add valueFormatter to number type to preserve decimals

export const allColumns: GridColumns[] = [
  {
    field: "UWI",
    headerName: "UWI",
    minWidth: 150,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: attribute.RECORD_TYPE.key,
    headerName: attribute.RECORD_TYPE.label,
    minWidth: 100,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: attribute.COUNTRY.key,
    headerName: attribute.COUNTRY.label,
    minWidth: 100,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: attribute.STATE_NAME.key,
    headerName: attribute.STATE_NAME.label,
    minWidth: 150,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: attribute.COUNTY.key,
    headerName: attribute.COUNTY.label,
    minWidth: 100,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: attribute.WELL_NAME.key,
    headerName: attribute.WELL_NAME.label,
    minWidth: 100,
    valueGetter: ({ value }) => value && value.toString().trim(),
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: attribute.WELL_NUMBER.key,
    headerName: attribute.WELL_NUMBER.label,
    minWidth: 100,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: attribute.FIELD_NAME.key,
    headerName: attribute.FIELD_NAME.label,
    minWidth: 100,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: attribute.MEASURED_DEPTH.key,
    headerName: attribute.MEASURED_DEPTH.label,
    type: "number",
    headerAlign: "left",
    minWidth: 130,
    valueGetter: (params) => formattedNumber(params.value),
    filterOperators: getNumberFilterOperators(),
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
  },
  {
    field: attribute.OPERATOR_NAME.key,
    headerName: attribute.OPERATOR_NAME.label,
    minWidth: 120,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: attribute.WELL_TYPE.key,
    headerName: attribute.WELL_TYPE.label,
    minWidth: 100,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: attribute.WELL_STATUS.key,
    headerName: attribute.WELL_STATUS.label,
    minWidth: 100,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: "ProducingFormation",
    headerName: attribute.DISPLAY_FORMATION.label,
    minWidth: 150,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: attribute.SLANT.key,
    headerName: attribute.SLANT.label,
    minWidth: 40,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: attribute.COMPLETION_DATE.key,
    headerName: attribute.COMPLETION_DATE.label,
    minWidth: 120,
    filterOperators: getDateFilterOperators(),
    bigQueryType: constants.COLUMN_TYPE_DATE,
  },
  {
    field: attribute.CUM_OIL.key,
    headerName: attribute.CUM_OIL.label,
    type: "number",
    headerAlign: "left",
    minWidth: 120,
    valueFormatter: (params) => params.value,
    filterOperators: getNumberFilterOperators(),
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
  },
  {
    field: attribute.CUM_GAS.key,
    headerName: attribute.CUM_GAS.label,
    type: "number",
    headerAlign: "left",
    minWidth: 120,
    valueFormatter: (params) => params.value,
    filterOperators: getNumberFilterOperators(),
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
  },
  {
    field: attribute.BASIN_NAME.key,
    headerName: attribute.BASIN_NAME.label,
    minWidth: 100,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: attribute.CUM_INJECTION_GAS.key,
    headerName: attribute.CUM_INJECTION_GAS.label,
    type: "number",
    headerAlign: "left",
    minWidth: 120,
    valueFormatter: (params) => params.value,
    filterOperators: getNumberFilterOperators(),
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
  },
  {
    field: attribute.CUM_INJECTION_LIQUID.key,
    headerName: attribute.CUM_INJECTION_LIQUID.label,
    type: "number",
    headerAlign: "left",
    minWidth: 140,
    valueFormatter: (params) => params.value,
    filterOperators: getNumberFilterOperators(),
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
  },
  {
    field: attribute.CUM_WATER.key,
    headerName: attribute.CUM_WATER.label,
    type: "number",
    headerAlign: "left",
    minWidth: 100,
    valueFormatter: (params) => params.value,
    filterOperators: getNumberFilterOperators(),
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
  },
  {
    field: attribute.FIRST_MONTH.key,
    headerName: attribute.FIRST_MONTH.label,
    minWidth: 100,
    filterOperators: getDateFilterOperators(),
    bigQueryType: constants.COLUMN_TYPE_DATE,
  },
  {
    // API Request -> field should be `InterpretedProducingFormation`
    field: "LandingZone",
    headerName: attribute.INTERPRETED_PRODUCING_FORMATION.label,
    minWidth: 100,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: attribute.LATERAL_LENGTH.key,
    headerName: attribute.LATERAL_LENGTH.label,
    type: "number",
    headerAlign: "left",
    minWidth: 100,
    valueGetter: (params) => formattedNumber(params.value),
    filterOperators: getNumberFilterOperators(),
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
  },
  {
    field: attribute.LAST_MONTH.key,
    headerName: attribute.LAST_MONTH.label,
    minWidth: 100,
    filterOperators: getDateFilterOperators(),
    bigQueryType: constants.COLUMN_TYPE_DATE,
  },
  {
    field: attribute.MAX_GAS.key,
    headerName: attribute.MAX_GAS.label,
    type: "number",
    headerAlign: "left",
    minWidth: 120,
    valueFormatter: (params) => params.value,
    filterOperators: getNumberFilterOperators(),
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
  },
  {
    field: attribute.MAX_GAS_PLUS_2.key,
    headerName: attribute.MAX_GAS_PLUS_2.label,
    type: "number",
    headerAlign: "left",
    minWidth: 150,
    valueFormatter: (params) => params.value,
    filterOperators: getNumberFilterOperators(),
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
  },
  {
    field: attribute.MAX_OIL.key,
    headerName: attribute.MAX_OIL.label,
    type: "number",
    headerAlign: "left",
    minWidth: 100,
    valueFormatter: (params) => params.value,
    filterOperators: getNumberFilterOperators(),
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
  },
  {
    field: attribute.MAX_OIL_PLUS_2.key,
    headerName: attribute.MAX_OIL_PLUS_2.label,
    type: "number",
    headerAlign: "left",
    minWidth: 140,
    valueFormatter: (params) => params.value,
    filterOperators: getNumberFilterOperators(),
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
  },
  {
    field: attribute.MAX_WATER.key,
    headerName: attribute.MAX_WATER.label,
    type: "number",
    headerAlign: "left",
    minWidth: 100,
    valueFormatter: (params) => params.value,
    filterOperators: getNumberFilterOperators(),
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
  },
  {
    field: attribute.MAX_WATER_PLUS_2.key,
    headerName: attribute.MAX_WATER_PLUS_2.label,
    type: "number",
    headerAlign: "left",
    minWidth: 150,
    valueFormatter: (params) => params.value,
    filterOperators: getNumberFilterOperators(),
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
  },
  {
    field: attribute.WATER_CUT_TOTAL_CUM.key,
    headerName: attribute.WATER_CUT_TOTAL_CUM.label,
    type: "number",
    headerAlign: "left",
    minWidth: 150,
    valueFormatter: (params) => params.value,
    filterOperators: getNumberFilterOperators(),
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
  },
  {
    field: attribute.WATER_OIL_RATIO_TOTAL_CUM.key,
    headerName: attribute.WATER_OIL_RATIO_TOTAL_CUM.label,
    type: "number",
    headerAlign: "left",
    minWidth: 150,
    valueFormatter: (params) => params.value,
    filterOperators: getNumberFilterOperators(),
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
  },
  {
    field: attribute.SPUD_DATE.key,
    headerName: attribute.SPUD_DATE.label,
    minWidth: 100,
    filterOperators: getDateFilterOperators(),
    bigQueryType: constants.COLUMN_TYPE_DATE,
  },
  {
    field: attribute.TOTAL_VERTICAL_DEPTH.key,
    headerName: attribute.TOTAL_VERTICAL_DEPTH.label,
    type: "number",
    headerAlign: "left",
    minWidth: 140,
    valueGetter: (params) => formattedNumber(params.value),
    filterOperators: getNumberFilterOperators(),
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
  },
  {
    field: attribute.SURFACE_UWI.key,
    headerName: attribute.SURFACE_UWI.label,
    minWidth: 150,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },

  // New Columns as of Release 14 - 1 of 3
  {
    field: attribute.ULTIMATE_OWNER.key,
    headerName: attribute.ULTIMATE_OWNER.label,
    minWidth: 190,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: "OriginalOperator",
    headerName: "Original Operator",
    minWidth: 130,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: attribute.LEASE_NAME.key,
    headerName: attribute.LEASE_NAME.label,
    minWidth: 160,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: attribute.LEASE_NAME_AND_ID.key,
    headerName: attribute.LEASE_NAME_AND_ID.label,
    minWidth: 140,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: attribute.PERF_INTERVAL_TOP.key,
    headerName: attribute.PERF_INTERVAL_TOP.label,
    type: "number",
    headerAlign: "left",
    minWidth: 170,
    valueGetter: (params) => formattedNumber(params.value),
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: attribute.PERF_INTERVAL_BOTTOM.key,
    headerName: attribute.PERF_INTERVAL_BOTTOM.label,
    type: "number",
    headerAlign: "left",
    minWidth: 190,
    valueGetter: (params) => formattedNumber(params.value),
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: "ElevationGround",
    headerName: "Elevation Ground",
    type: "number",
    headerAlign: "left",
    minWidth: 130,
    valueGetter: (params) => formattedNumber(params.value),
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: "ElevationKellyBushing",
    headerName: "Elevation Kelly Bushing",
    type: "number",
    headerAlign: "left",
    minWidth: 170,
    valueGetter: (params) => formattedNumber(params.value),
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: "LastInjectionType",
    headerName: "Injection Type",
    minWidth: 100,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: "LastInjectionFormation",
    headerName: "Injection Formation",
    minWidth: 140,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: "DispositionType",
    headerName: "Gas Disposition Type",
    minWidth: 150,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: attribute.HAS_PRODUCTION.key,
    headerName: attribute.HAS_PRODUCTION.label,
    minWidth: 120,
    bigQueryType: constants.COLUMN_TYPE_BOOLEAN,
  },
  {
    field: attribute.HAS_FORECAST.key,
    headerName: attribute.HAS_FORECAST.label,
    minWidth: 110,
    bigQueryType: constants.COLUMN_TYPE_BOOLEAN,
  },
  {
    field: attribute.TEST.key,
    headerName: attribute.TEST.label,
    minWidth: 100,
    bigQueryType: constants.COLUMN_TYPE_BOOLEAN,
  },
  {
    field: attribute.PERF.key,
    headerName: attribute.PERF.label,
    minWidth: 120,
    bigQueryType: constants.COLUMN_TYPE_BOOLEAN,
  },
  {
    field: "HasDisposition",
    headerName: "Has Disposition",
    minWidth: 120,
    bigQueryType: constants.COLUMN_TYPE_BOOLEAN,
  },
  {
    field: "HasCompletion",
    headerName: "Has Completions",
    minWidth: 120,
    bigQueryType: constants.COLUMN_TYPE_BOOLEAN,
  },
  {
    field: attribute.HAS_INJECTION.key,
    headerName: attribute.HAS_INJECTION.label,
    minWidth: 120,
    bigQueryType: constants.COLUMN_TYPE_BOOLEAN,
  },
  {
    field: "ActiveInjection",
    headerName: "Active Injection",
    minWidth: 120,
    bigQueryType: constants.COLUMN_TYPE_BOOLEAN,
  },
  {
    field: "HasDrillString",
    headerName: "Has Casing",
    minWidth: 100,
    bigQueryType: constants.COLUMN_TYPE_BOOLEAN,
  },
  {
    field: attribute.HAS_VENT_FLARE.key,
    headerName: attribute.HAS_VENT_FLARE.label,
    minWidth: 130,
    bigQueryType: constants.COLUMN_TYPE_BOOLEAN,
  },
  {
    field: "ActiveVentFlare",
    headerName: "Active Vent Flare",
    minWidth: 130,
    bigQueryType: constants.COLUMN_TYPE_BOOLEAN,
  },
  {
    field: "HasReportedTops",
    headerName: "Has Reported Formation Tops",
    minWidth: 200,
    bigQueryType: constants.COLUMN_TYPE_BOOLEAN,
  },
  {
    field: attribute.HAS_WELL_ECONOMICS.key,
    headerName: attribute.HAS_WELL_ECONOMICS.label,
    minWidth: 150,
    bigQueryType: constants.COLUMN_TYPE_BOOLEAN,
  },
  {
    field: attribute.OFFSHORE_FLG.key,
    headerName: attribute.OFFSHORE_FLG.label,
    minWidth: 110,
    bigQueryType: constants.COLUMN_TYPE_BOOLEAN,
  },
  {
    field: attribute.HAS_LAS.key,
    headerName: attribute.HAS_LAS.label,
    minWidth: 150,
    bigQueryType: constants.COLUMN_TYPE_BOOLEAN,
  },
  {
    field: attribute.HAS_RASTER.key,
    headerName: attribute.HAS_RASTER.label,
    minWidth: 110,
    bigQueryType: constants.COLUMN_TYPE_BOOLEAN,
  },

  // New Columns as of Release 14 - 2 of 3
  {
    field: attribute.STATE_LEASE_ID.key,
    headerName: attribute.STATE_LEASE_ID.label,
    minWidth: 140,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: "StateWellID",
    headerName: "State Well ID",
    minWidth: 120,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: attribute.TD_DATE.key,
    headerName: attribute.TD_DATE.label,
    minWidth: 100,
    filterOperators: getDateFilterOperators(),
    bigQueryType: constants.COLUMN_TYPE_DATE,
  },
  {
    field: "PlugDate",
    headerName: "Plug Date",
    minWidth: 100,
    filterOperators: getDateFilterOperators(),
    bigQueryType: constants.COLUMN_TYPE_DATE,
  },
  {
    field: "DrillingDays",
    headerName: "Drilling Days",
    minWidth: 120,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_INTEGER,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: attribute.NUMBER_OF_STAGES.key,
    headerName: attribute.NUMBER_OF_STAGES.label,
    type: "number",
    headerAlign: "left",
    minWidth: 130,
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_INTEGER,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: "FractureFluidType",
    headerName: "Frac Fluid Type",
    minWidth: 120,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: attribute.FRAC_FLUID_AMOUNT.key,
    headerName: attribute.FRAC_FLUID_AMOUNT.label,
    minWidth: 130,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: "ProppantType",
    headerName: "Proppant Type",
    minWidth: 110,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: attribute.PROPPANT_AMOUNT.key,
    headerName: attribute.PROPPANT_AMOUNT.label,
    minWidth: 120,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: attribute.MAX_TREATMENT_RATE.key,
    headerName: attribute.MAX_TREATMENT_RATE.label,
    minWidth: 140,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: attribute.MAX_TREATMENT_PRESSURE.key,
    headerName: attribute.MAX_TREATMENT_PRESSURE.label,
    minWidth: 160,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: attribute.ACID_AMOUNT.key,
    headerName: attribute.ACID_AMOUNT.label,
    minWidth: 120,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: "TreatmentRemarks",
    headerName: "Treatment Remarks",
    minWidth: 160,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: attribute.PROPPANT_AMOUNT_PER_FT.key,
    headerName: attribute.PROPPANT_AMOUNT_PER_FT.label,
    minWidth: 170,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: attribute.FRAC_FLUID_AMOUNT_PER_FT.key,
    headerName: attribute.FRAC_FLUID_AMOUNT_PER_FT.label,
    minWidth: 190,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: attribute.BOE_MAX_PER_FT.key,
    headerName: attribute.BOE_MAX_PER_FT.label,
    minWidth: 150,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: attribute.EUR_PER_FT.key,
    headerName: attribute.EUR_PER_FT.label,
    minWidth: 140,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: attribute.SECTION.key,
    headerName: attribute.SECTION.label,
    minWidth: 100,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: attribute.TOWNSHIP.key,
    headerName: attribute.TOWNSHIP.label,
    minWidth: 110,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: attribute.TOWNSHIP_DIRECTION.key,
    headerName: attribute.TOWNSHIP_DIRECTION.label,
    minWidth: 140,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: attribute.RANGE.key,
    headerName: attribute.RANGE.label,
    minWidth: 110,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: attribute.RANGE_DIRECTION.key,
    headerName: attribute.RANGE_DIRECTION.label,
    minWidth: 130,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: "QuarterQuarter",
    headerName: "Quarter Quarter",
    minWidth: 130,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },

  // New Columns as of Release 14 - 3 of 3
  {
    field: "DisplayLocation",
    headerName: "Location",
    minWidth: 110,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: "Survey",
    headerName: "Survey Name",
    minWidth: 120,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: attribute.DISTRICT.key,
    headerName: attribute.DISTRICT.label,
    minWidth: 110,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: "Abstract",
    headerName: "Abstract",
    minWidth: 110,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: "Block",
    headerName: "Block Name",
    minWidth: 120,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: attribute.AREA.key,
    headerName: attribute.AREA.label,
    minWidth: 110,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: attribute.OFFSHORE_BLOCK.key,
    headerName: attribute.OFFSHORE_BLOCK.label,
    minWidth: 130,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: "DistanceNS",
    headerName: "DistanceNS",
    minWidth: 120,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: "DistanceNSD",
    headerName: "DistanceNSD",
    minWidth: 120,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: "DistanceEW",
    headerName: "DistanceEW",
    minWidth: 120,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: "DistanceEWD",
    headerName: "DistanceEWD",
    minWidth: 130,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: attribute.CUM_BOE.key,
    headerName: attribute.CUM_BOE.label,
    minWidth: 130,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: attribute.CUM_GOR.key,
    headerName: attribute.CUM_GOR.label,
    minWidth: 140,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: "CumVentFlareVol",
    headerName: "Vent Flare Cum",
    minWidth: 120,
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: "WellForecastBOERemaining",
    headerName: "BOE Remaining",
    minWidth: 120,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: attribute.WELL_FORECAST_BOE_ULTIMATE.key,
    headerName: attribute.WELL_FORECAST_BOE_ULTIMATE.label,
    minWidth: 120,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: "WellForecastGasRemaining",
    headerName: "Gas Remaining",
    minWidth: 120,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: attribute.WELL_FORECAST_GAS_ULTIMATE.key,
    headerName: attribute.WELL_FORECAST_GAS_ULTIMATE.label,
    minWidth: 120,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: "WellForecastOilRemaining",
    headerName: "Oil Remaining",
    minWidth: 120,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: attribute.WELL_FORECAST_OIL_ULTIMATE.key,
    headerName: attribute.WELL_FORECAST_OIL_ULTIMATE.label,
    minWidth: 120,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: "WellForecastWaterRemaining",
    headerName: "Water Remaining",
    minWidth: 130,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: attribute.WELL_FORECAST_WATER_ULTIMATE.key,
    headerName: attribute.WELL_FORECAST_WATER_ULTIMATE.label,
    minWidth: 120,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: "WellForecastGORUltimate",
    headerName: "GOR Ultimate",
    minWidth: 120,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: "WellForecastWaterCutUltimate",
    headerName: "Water Cut Ultimate",
    minWidth: 140,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
    filterOperators: getNumberFilterOperators(),
  },

  // New Columns as of Sprint 16
  {
    field: attribute.CLOSEST_HORIZONTAL_DISTANCE_IN_ZONE.key,
    headerName: attribute.CLOSEST_HORIZONTAL_DISTANCE_IN_ZONE.label,
    minWidth: 160,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_FLOAT,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: attribute.CLOSEST_HORIZONTAL_DISTANCE.key,
    headerName: attribute.CLOSEST_HORIZONTAL_DISTANCE.label,
    minWidth: 160,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_FLOAT,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: attribute.CLOSEST_VERTICAL_DISTANCE.key,
    headerName: attribute.CLOSEST_VERTICAL_DISTANCE.label,
    minWidth: 160,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_FLOAT,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: attribute.COUNT_OF_PARENT_WELLS.key,
    headerName: attribute.COUNT_OF_PARENT_WELLS.label,
    minWidth: 160,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_INTEGER,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: attribute.COUNT_OF_CHILD_WELLS.key,
    headerName: attribute.COUNT_OF_CHILD_WELLS.label,
    minWidth: 160,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_INTEGER,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: attribute.COUNT_OF_SIBLING_WELLS.key,
    headerName: attribute.COUNT_OF_SIBLING_WELLS.label,
    minWidth: 160,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_INTEGER,
    filterOperators: getNumberFilterOperators(),
  },

  // Release: Oct 25th, 2023
  {
    field: attribute.OIL_EUR_PER_FT.key,
    headerName: attribute.OIL_EUR_PER_FT.label,
    minWidth: 160,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: attribute.GAS_EUR_PER_FT.key,
    headerName: attribute.GAS_EUR_PER_FT.label,
    minWidth: 160,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: attribute.OIL_CUM_PER_FT.key,
    headerName: attribute.OIL_CUM_PER_FT.label,
    minWidth: 160,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: attribute.GAS_CUM_PER_FT.key,
    headerName: attribute.GAS_CUM_PER_FT.label,
    minWidth: 160,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: attribute.WATER_CUM_PER_FT.key,
    headerName: attribute.WATER_CUM_PER_FT.label,
    minWidth: 160,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: attribute.BOE_CUM_PER_FT.key,
    headerName: attribute.BOE_CUM_PER_FT.label,
    minWidth: 160,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: "BOEIP30",
    headerName: attribute.MAX_BOE.label, //BOE_IP30
    minWidth: 130,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: "OilAlgorithm",
    headerName: "Oil Algorithm",
    minWidth: 140,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: "OilInitialRate",
    headerName: "Oil Initial Rate",
    minWidth: 140,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_FLOAT,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: "OilDeclineRate",
    headerName: "Oil Decline Rate",
    minWidth: 140,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: "OilExponent",
    headerName: "Oil B-Factor",
    minWidth: 120,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_FLOAT,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: "OilStart",
    headerName: "Oil Start",
    minWidth: 120,
    filterOperators: getDateFilterOperators(),
    bigQueryType: constants.COLUMN_TYPE_DATE,
  },
  {
    field: "GasAlgorithm",
    headerName: "Gas Algorithm",
    minWidth: 120,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: "GasInitialRate",
    headerName: "Gas Initial Rate",
    minWidth: 160,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: "GasDeclineRate",
    headerName: "Gas Decline Rate",
    minWidth: 160,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: "GasExponent",
    headerName: "Gas B-Factor",
    minWidth: 120,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_FLOAT,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: "GasStart",
    headerName: "Gas Start",
    minWidth: 120,
    filterOperators: getDateFilterOperators(),
    bigQueryType: constants.COLUMN_TYPE_DATE,
  },
  {
    field: "WaterAlgorithm",
    headerName: "Water Algorithm",
    minWidth: 120,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: "WaterInitialRate",
    headerName: "Water Initial Rate",
    minWidth: 160,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_FLOAT,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: "WaterDeclineRate",
    headerName: "Water Decline Rate",
    minWidth: 160,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_FLOAT,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: "WaterExponent",
    headerName: "Water B-Factor",
    minWidth: 120,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_FLOAT,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: "WaterStart",
    headerName: "Water Start",
    minWidth: 120,
    filterOperators: getDateFilterOperators(),
    bigQueryType: constants.COLUMN_TYPE_DATE,
  },
  {
    field: attribute.BOE_IP90.key,
    headerName: attribute.BOE_IP90.label,
    minWidth: 160,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
    filterOperators: getNumberFilterOperators(),
  },

  {
    field: "SurfaceLatitudeNAD83",
    headerName: "SurfaceLatitude_NAD83",
    minWidth: 180,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_BIG_NUMERIC,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: "SurfaceLongitudeNAD83",
    headerName: "SurfaceLongitude_NAD83",
    minWidth: 180,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_BIG_NUMERIC,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: "BottomLatitudeNAD83",
    headerName: "BottomLatitude_NAD83",
    minWidth: 180,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_BIG_NUMERIC,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: "BottomLongitudeNAD83",
    headerName: "BottomLongitude_NAD83",
    minWidth: 180,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_BIG_NUMERIC,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: "SurfaceLatitudeNAD27",
    headerName: "SurfaceLatitude_NAD27",
    minWidth: 180,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_BIG_NUMERIC,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: "SurfaceLongitudeNAD27",
    headerName: "SurfaceLongitude_NAD27",
    minWidth: 180,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_BIG_NUMERIC,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: "BottomLatitudeNAD27",
    headerName: "BottomLatitude_NAD27",
    minWidth: 180,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_BIG_NUMERIC,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: "BottomLongitudeNAD27",
    headerName: "BottomLongitude_NAD27",
    minWidth: 180,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_BIG_NUMERIC,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: "SurfaceLatitudeEPSG3857",
    headerName: "SurfaceLatitude_EPSG3857",
    minWidth: 200,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_FLOAT,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: "SurfaceLongitudeEPSG3857",
    headerName: "SurfaceLongitude_EPSG3857",
    minWidth: 200,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_FLOAT,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: "BottomLatitudeEPSG3857",
    headerName: "BottomLatitude_EPSG3857",
    minWidth: 200,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_FLOAT,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: "BottomLongitudeEPSG3857",
    headerName: "BottomLongitude_EPSG3857",
    minWidth: 200,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_FLOAT,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: "SurfaceLatitudeWGS84",
    headerName: "SurfaceLatitude_WGS84",
    minWidth: 180,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_BIG_NUMERIC,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: "SurfaceLongitudeWGS84",
    headerName: "SurfaceLongitude_WGS84",
    minWidth: 180,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_BIG_NUMERIC,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: "BottomLatitudeWGS84",
    headerName: "BottomLatitude_WGS84",
    minWidth: 180,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_BIG_NUMERIC,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: "BottomLongitudeWGS84",
    headerName: "BottomLongitude_WGS84",
    minWidth: 180,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_BIG_NUMERIC,
    filterOperators: getNumberFilterOperators(),
  },
  // 2023-12-19 - Release
  {
    field: "Vshale",
    headerName: "Vshale",
    minWidth: 130,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: "Density",
    headerName: "Density",
    minWidth: 130,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: "NeutronPorosity",
    headerName: "Neutron Porosity",
    minWidth: 130,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: "Resistivity",
    headerName: "Resistivity",
    minWidth: 130,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: "Sonic",
    headerName: "Sonic",
    minWidth: 130,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: attribute.HAS_DST.key,
    headerName: attribute.HAS_DST.label,
    minWidth: 120,
    bigQueryType: constants.COLUMN_TYPE_BOOLEAN,
  },
  //2024-01-31 Release - Sprint 33
  {
    field: attribute.RECENT_1MO_GAS.key,
    headerName: attribute.RECENT_1MO_GAS.label,
    minWidth: 120,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    filterOperators: getNumberFilterOperators(),
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
  },
  {
    field: attribute.RECENT_1MO_WATER.key,
    headerName: attribute.RECENT_1MO_WATER.label,
    minWidth: 120,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    filterOperators: getNumberFilterOperators(),
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
  },
  {
    field: attribute.RECENT_1MO_OIL.key,
    headerName: attribute.RECENT_1MO_OIL.label,
    minWidth: 120,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    filterOperators: getNumberFilterOperators(),
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
  },
  {
    field: attribute.RECENT_6MO_GAS.key,
    headerName: attribute.RECENT_6MO_GAS.label,
    minWidth: 120,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    filterOperators: getNumberFilterOperators(),
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
  },
  {
    field: attribute.RECENT_6MO_WATER.key,
    headerName: attribute.RECENT_6MO_WATER.label,
    minWidth: 120,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    filterOperators: getNumberFilterOperators(),
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
  },
  {
    field: attribute.RECENT_6MO_OIL.key,
    headerName: attribute.RECENT_6MO_OIL.label,
    minWidth: 120,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    filterOperators: getNumberFilterOperators(),
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
  },
  {
    field: attribute.RECENT_1YR_OIL.key,
    headerName: attribute.RECENT_1YR_OIL.label,
    minWidth: 120,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    filterOperators: getNumberFilterOperators(),
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
  },
  {
    field: attribute.RECENT_1YR_WATER.key,
    headerName: attribute.RECENT_1YR_WATER.label,
    minWidth: 120,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    filterOperators: getNumberFilterOperators(),
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
  },
  {
    field: attribute.RECENT_1YR_GAS.key,
    headerName: attribute.RECENT_1YR_GAS.label,
    minWidth: 120,
    type: "number",
    headerAlign: "left",
    valueFormatter: (params) => params.value,
    filterOperators: getNumberFilterOperators(),
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
  },
  {
    field: attribute.LOE_COST.key,
    headerName: attribute.LOE_COST.label,
    minWidth: 120,
    type: "number",
    headerAlign: "left",
    valueGetter: (params) =>
      GRID_COLUMN_FORMATTING_RULES[attribute.LOE_COST.key].formatValue(
        params.value
      ),
    filterOperators: getNumberFilterOperators(),
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
  },
  {
    field: attribute.GPT_COST.key,
    headerName: attribute.GPT_COST.label,
    minWidth: 120,
    type: "number",
    headerAlign: "left",
    valueGetter: (params) =>
      GRID_COLUMN_FORMATTING_RULES[attribute.GPT_COST.key].formatValue(
        params.value
      ),
    filterOperators: getNumberFilterOperators(),
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
  },
  {
    field: attribute.WATER_COST.key,
    headerName: attribute.WATER_COST.label,
    minWidth: 120,
    type: "number",
    headerAlign: "left",
    valueGetter: (params) =>
      GRID_COLUMN_FORMATTING_RULES[attribute.WATER_COST.key].formatValue(
        params.value
      ),
    filterOperators: getNumberFilterOperators(),
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
  },
  {
    field: attribute.FIXED_COST.key,
    headerName: attribute.FIXED_COST.label,
    minWidth: 120,
    type: "number",
    headerAlign: "left",
    valueGetter: (params) =>
      GRID_COLUMN_FORMATTING_RULES[attribute.FIXED_COST.key].formatValue(
        params.value
      ),
    filterOperators: getNumberFilterOperators(),
    bigQueryType: constants.COLUMN_TYPE_INTEGER,
  },
  {
    field: attribute.DRILLING_COMPLETION_CAP_EX.key,
    headerName: attribute.DRILLING_COMPLETION_CAP_EX.label,
    minWidth: 120,
    type: "number",
    headerAlign: "left",
    valueGetter: (params) =>
      GRID_COLUMN_FORMATTING_RULES[
        attribute.DRILLING_COMPLETION_CAP_EX.key
      ].formatValue(params.value),
    filterOperators: getNumberFilterOperators(),
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
  },
  {
    field: attribute.INFRA_CAP_EX.key,
    headerName: attribute.INFRA_CAP_EX.label,
    minWidth: 120,
    type: "number",
    headerAlign: "left",
    valueGetter: (params) =>
      GRID_COLUMN_FORMATTING_RULES[attribute.INFRA_CAP_EX.key].formatValue(
        params.value
      ),
    filterOperators: getNumberFilterOperators(),
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
  },
  {
    field: attribute.WORKING_INTEREST.key,
    headerName: attribute.WORKING_INTEREST.label,
    minWidth: 120,
    type: "number",
    headerAlign: "left",
    valueGetter: (params) =>
      GRID_COLUMN_FORMATTING_RULES[attribute.WORKING_INTEREST.key].formatValue(
        params.value
      ),
    filterOperators: getNumberFilterOperators(),
    bigQueryType: constants.COLUMN_TYPE_INTEGER,
  },
  {
    field: attribute.ROYALTY.key,
    headerName: attribute.ROYALTY.label,
    minWidth: 120,
    type: "number",
    headerAlign: "left",
    valueGetter: (params) =>
      GRID_COLUMN_FORMATTING_RULES[attribute.ROYALTY.key].formatValue(
        params.value
      ),
    filterOperators: getNumberFilterOperators(),
    bigQueryType: constants.COLUMN_TYPE_INTEGER,
  },
  {
    field: attribute.INTERNAL_RATE_OF_RETURN.key,
    headerName: attribute.INTERNAL_RATE_OF_RETURN.label,
    minWidth: 120,
    type: "number",
    headerAlign: "left",
    valueGetter: (params) =>
      GRID_COLUMN_FORMATTING_RULES[
        attribute.INTERNAL_RATE_OF_RETURN.key
      ].formatValue(params.value),
    filterOperators: getNumberFilterOperators(),
    bigQueryType: constants.COLUMN_TYPE_INTEGER,
  },
  {
    field: attribute.BREAK_EVEN_PRICE.key,
    headerName: attribute.BREAK_EVEN_PRICE.label,
    minWidth: 120,
    type: "number",
    headerAlign: "left",
    valueGetter: (params) =>
      GRID_COLUMN_FORMATTING_RULES[attribute.BREAK_EVEN_PRICE.key].formatValue(
        params.value
      ),
    filterOperators: getNumberFilterOperators(),
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
  },
  {
    field: attribute.NET_PRESENT_VALUE.key,
    headerName: attribute.NET_PRESENT_VALUE.label,
    minWidth: 120,
    type: "number",
    headerAlign: "left",
    valueGetter: (params) =>
      GRID_COLUMN_FORMATTING_RULES[attribute.NET_PRESENT_VALUE.key].formatValue(
        params.value
      ),
    filterOperators: getNumberFilterOperators(),
    bigQueryType: constants.COLUMN_TYPE_INTEGER,
  },
  {
    field: attribute.PAYOUT.key,
    headerName: attribute.PAYOUT.label,
    minWidth: 120,
    type: "number",
    headerAlign: "left",
    valueGetter: (params) =>
      GRID_COLUMN_FORMATTING_RULES[attribute.PAYOUT.key].formatValue(
        params.value
      ),
    filterOperators: getNumberFilterOperators(),
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
  },

  //Permits Attributes
  {
    field: attribute.PERMIT_NUMBER.key,
    headerName: attribute.PERMIT_NUMBER.label,
    minWidth: 120,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: attribute.PERMIT_APPROVAL_DATE.key,
    headerName: attribute.PERMIT_APPROVAL_DATE.label,
    minWidth: 140,
    bigQueryType: constants.COLUMN_TYPE_DATE,
  },
  {
    field: attribute.PERMIT_POSTED_DATE.key,
    headerName: attribute.PERMIT_POSTED_DATE.label,
    minWidth: 120,
    bigQueryType: constants.COLUMN_TYPE_DATE,
  },
  // {
  //   field: "WellPurpose",
  //   headerName: "Permit Well Purpose",
  //   minWidth: 120,
  //   bigQueryType: constants.COLUMN_TYPE_DATE,
  // },
  {
    field: "PermitType",
    headerName: "Permit Type",
    minWidth: 120,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: attribute.PERMIT_TOTAL_DEPTH.key,
    headerName: attribute.PERMIT_TOTAL_DEPTH.label,
    minWidth: 120,
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
    type: "number",
    valueGetter: (params) => formattedNumber(params.value),
  },
  {
    field: attribute.PERMIT_HAS_H2S.key,
    headerName: attribute.PERMIT_HAS_H2S.label,
    minWidth: 120,
    bigQueryType: constants.COLUMN_TYPE_BOOLEAN,
  },
  {
    field: "OperatorContact",
    headerName: "Operator Contact",
    minWidth: 120,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: "OperatorPhoneNumber",
    headerName: "Operator Phone Number",
    minWidth: 120,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: "OperatorAddress",
    headerName: "Operator Address",
    minWidth: 140,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: "OperatorCity",
    headerName: "Operator City",
    minWidth: 120,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: "OperatorState",
    headerName: "Operator State",
    minWidth: 120,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: "OperatorZipCode",
    headerName: "Operator ZipCode",
    minWidth: 120,
    bigQueryType: constants.COLUMN_TYPE_STRING,
  },
  {
    field: attribute.PERMIT_FORMATION_DEPTH.key,
    headerName: attribute.PERMIT_FORMATION_DEPTH.label,
    minWidth: 120,
    bigQueryType: constants.COLUMN_TYPE_NUMERIC,
  },
];

export const columnsNumDataType = allColumns
  .filter((item) => item.type && item.type === "number")
  .map((obj) => obj.field);

export const changeKey = (columnKey: string) => {
  switch (columnKey) {
    case "ProducingFormation":
      return "DisplayFormation";
    case "LandingZone":
      return "InterpretedProducingFormation";
    case "BOEIP30":
      return "MaxBOE";
    case "SurfaceLatitudeNAD27":
      return "SurfaceLatitude_NAD27";
    case "SurfaceLongitudeNAD27":
      return "SurfaceLongitude_NAD27";
    case "BottomLatitudeNAD27":
      return "BottomLatitude_NAD27";
    case "BottomLongitudeNAD27":
      return "BottomLongitude_NAD27";
    case "SurfaceLatitudeNAD83":
      return "SurfaceLatitude_NAD83";
    case "SurfaceLongitudeNAD83":
      return "SurfaceLongitude_NAD83";
    case "BottomLatitudeNAD83":
      return "BottomLatitude_NAD83";
    case "BottomLongitudeNAD83":
      return "BottomLongitude_NAD83";
    case "SurfaceLatitudeWGS84":
      return "SurfaceLatitude_WGS84";
    case "SurfaceLongitudeWGS84":
      return "SurfaceLongitude_WGS84";
    case "BottomLatitudeWGS84":
      return "BottomLatitude_WGS84";
    case "BottomLongitudeWGS84":
      return "BottomLongitude_WGS84";
    case "SurfaceLatitudeEPSG3857":
      return "SurfaceLatitude_EPSG3857";
    case "SurfaceLongitudeEPSG3857":
      return "SurfaceLongitude_EPSG3857";
    case "BottomLatitudeEPSG3857":
      return "BottomLatitude_EPSG3857";
    case "BottomLongitudeEPSG3857":
      return "BottomLongitude_EPSG3857";
    default:
      return columnKey;
  }
};

export const changeAttributeKey = (attribute: string) => {
  switch (attribute) {
    case "DisplayFormation":
      return "ProducingFormation";
    case "InterpretedProducingFormation":
      return "LandingZone";
    case "MaxBOE":
      return "BOEIP30";
    case "SurfaceLatitude_NAD27":
      return "SurfaceLatitudeNAD27";
    case "SurfaceLongitude_NAD27":
      return "SurfaceLongitudeNAD27";
    case "BottomLatitude_NAD27":
      return "BottomLatitudeNAD27";
    case "BottomLongitude_NAD27":
      return "BottomLongitudeNAD27";
    case "SurfaceLatitude_NAD83":
      return "SurfaceLatitudeNAD83";
    case "SurfaceLongitude_NAD83":
      return "SurfaceLongitudeNAD83";
    case "BottomLatitude_NAD83":
      return "BottomLatitudeNAD83";
    case "BottomLongitude_NAD83":
      return "BottomLongitudeNAD83";
    case "SurfaceLatitude_WGS84":
      return "SurfaceLatitudeWGS84";
    case "SurfaceLongitude_WGS84":
      return "SurfaceLongitudeWGS84";
    case "BottomLatitude_WGS84":
      return "BottomLatitudeWGS84";
    case "BottomLongitude_WGS84":
      return "BottomLongitudeWGS84";
    case "SurfaceLatitude_EPSG3857":
      return "SurfaceLatitudeEPSG3857";
    case "SurfaceLongitude_EPSG3857":
      return "SurfaceLongitudeEPSG3857";
    case "BottomLatitude_EPSG3857":
      return "BottomLatitudeEPSG3857";
    case "BottomLongitude_EPSG3857":
      return "BottomLongitudeEPSG3857";
    default:
      return attribute;
  }
};

export const dateFields = allColumns
  .filter((item) => item.bigQueryType === constants.COLUMN_TYPE_DATE)
  .map((col) => col.field);

export const numberFields = allColumns
  .filter(
    (item) =>
      item.bigQueryType === constants.COLUMN_TYPE_INTEGER ||
      item.bigQueryType === constants.COLUMN_TYPE_NUMERIC ||
      item.bigQueryType === constants.COLUMN_TYPE_BIG_NUMERIC ||
      item.bigQueryType === constants.COLUMN_TYPE_FLOAT
  )
  .map((col) => col.field);

export const bigNumericFields = allColumns
  .filter(
    (item) =>
      item.bigQueryType === constants.COLUMN_TYPE_BIG_NUMERIC ||
      item.bigQueryType === constants.COLUMN_TYPE_FLOAT
  )
  .map((col) => col.field);

export const decimalFields = allColumns
  .filter(
    (item) =>
      item.bigQueryType === constants.COLUMN_TYPE_NUMERIC ||
      item.bigQueryType === constants.COLUMN_TYPE_BIG_NUMERIC ||
      item.bigQueryType === constants.COLUMN_TYPE_FLOAT
  )
  .map((col) => col.field);

export const booleanFields = allColumns
  .filter((item) => item.bigQueryType === constants.COLUMN_TYPE_BOOLEAN)
  .map((col) => col.field);

export const varcharFields = allColumns
  .filter((item) => item.bigQueryType === constants.COLUMN_TYPE_STRING)
  .map((col) => col.field);
