import { FC, useState } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
} from "@mui/material";

import { WellData } from "../../../../types/panels/wellPanel/wellPanel";

import * as attributes from "../../../../constants/attributes";
import { PERMIT_INFO_HEADER_ATTRIBUTES } from "../../../../constants/panels/permitPanel/permit";

import useAttributeUnit from "../../../../customHooks/common/useAttributeUnit";

import { truncateStringDecimal } from "../../../../utils/helper2";
import { checkFormatValidation } from "../../../../utils/panel/wellPanel/fieldValidation";

import WellInfoText from "../../../common/WellInfoText";

interface AccordionSectionCardProps extends Omit<AccordionProps, "children"> {
  data: WellData;
}

const AccordionSectionCard: FC<AccordionSectionCardProps> = ({
  data,
  ...props
}) => {
  const { getAttributeUnit } = useAttributeUnit();
  const [isExpanded, setIsExpanded] = useState(false);

  const handleOnChange = (event: React.SyntheticEvent, expanded: boolean) => {
    setIsExpanded(expanded);
  };

  return (
    <Accordion
      expanded={isExpanded}
      onChange={handleOnChange}
      className={"accordion-section-card-container"}
      {...props}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <div className="well-accordion-container">
          <div className="well-info-item-one-line">
            <div className="well-label-one-line-grouped-info">
              <div className="well-info-one-line-label">
                {PERMIT_INFO_HEADER_ATTRIBUTES.PERMIT_NUMBER.label}
                {": "}
              </div>
              <div className="well-info-one-line-value">
                {checkFormatValidation(
                  data[PERMIT_INFO_HEADER_ATTRIBUTES.PERMIT_NUMBER.key]
                )}
              </div>
            </div>
          </div>

          <div className="well-info-container">
            <div className="well-info-item">
              <WellInfoText
                label={PERMIT_INFO_HEADER_ATTRIBUTES.POSTED_DATE.label}
                value={checkFormatValidation(
                  data[PERMIT_INFO_HEADER_ATTRIBUTES.POSTED_DATE.key]
                )}
              />
            </div>

            {isExpanded ? (
              <>
                <div className="well-info-item">
                  <WellInfoText
                    label={PERMIT_INFO_HEADER_ATTRIBUTES.APPROVED_DATE.label}
                    value={checkFormatValidation(
                      data[PERMIT_INFO_HEADER_ATTRIBUTES.APPROVED_DATE.key]
                    )}
                  />
                </div>
                <div className="well-info-item">
                  <WellInfoText
                    label={PERMIT_INFO_HEADER_ATTRIBUTES.SLANT.label}
                    value={checkFormatValidation(
                      data[PERMIT_INFO_HEADER_ATTRIBUTES.SLANT.key]
                    )}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="well-info-item">
                  <WellInfoText
                    label={PERMIT_INFO_HEADER_ATTRIBUTES.PROPOSED_DEPTH.label}
                    value={`${checkFormatValidation(
                      data[PERMIT_INFO_HEADER_ATTRIBUTES.PROPOSED_DEPTH.key]
                    )} ${getAttributeUnit(attributes.PERMIT_TOTAL_DEPTH.key)}`}
                  />
                </div>
                <div className="well-info-item">
                  <WellInfoText
                    label={PERMIT_INFO_HEADER_ATTRIBUTES.FORMATION.label}
                    value={checkFormatValidation(
                      data[PERMIT_INFO_HEADER_ATTRIBUTES.FORMATION.key]
                    )}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="well-accordion-container">
          <div className="well-info-container">
            <div className="well-info-item">
              <WellInfoText
                label={PERMIT_INFO_HEADER_ATTRIBUTES.WELL_TYPE.label}
                value={checkFormatValidation(
                  data[PERMIT_INFO_HEADER_ATTRIBUTES.WELL_TYPE.key]
                )}
              />
            </div>

            <div className="well-info-item">
              <WellInfoText
                label={PERMIT_INFO_HEADER_ATTRIBUTES.PERMIT_PURPOSE.label}
                value={checkFormatValidation(
                  data[PERMIT_INFO_HEADER_ATTRIBUTES.PERMIT_PURPOSE.key]
                )}
              />
            </div>

            <div className="well-info-item">
              <WellInfoText
                label={PERMIT_INFO_HEADER_ATTRIBUTES.PERMIT_TYPE.label}
                value={checkFormatValidation(
                  data[PERMIT_INFO_HEADER_ATTRIBUTES.PERMIT_TYPE.key]
                )}
              />
            </div>

            <div className="well-info-item">
              <WellInfoText
                label={PERMIT_INFO_HEADER_ATTRIBUTES.SURFACE_LATITUDE.label}
                value={checkFormatValidation(
                  truncateStringDecimal(
                    data[PERMIT_INFO_HEADER_ATTRIBUTES.SURFACE_LATITUDE.key],
                    6
                  )
                )}
              />
            </div>

            <div className="well-info-item">
              <WellInfoText
                label={PERMIT_INFO_HEADER_ATTRIBUTES.SURFACE_LONGTITUDE.label}
                value={checkFormatValidation(
                  truncateStringDecimal(
                    data[PERMIT_INFO_HEADER_ATTRIBUTES.SURFACE_LONGTITUDE.key],
                    6
                  )
                )}
              />
            </div>

            <div className="well-info-item">
              <WellInfoText
                label={PERMIT_INFO_HEADER_ATTRIBUTES.FORMATION.label}
                value={checkFormatValidation(
                  data[PERMIT_INFO_HEADER_ATTRIBUTES.FORMATION.key]
                )}
              />
            </div>

            <div className="well-info-item">
              <WellInfoText
                label={PERMIT_INFO_HEADER_ATTRIBUTES.BOTTOM_LATITUDE.label}
                value={checkFormatValidation(
                  truncateStringDecimal(
                    data[PERMIT_INFO_HEADER_ATTRIBUTES.BOTTOM_LATITUDE.key],
                    6
                  )
                )}
              />
            </div>

            <div className="well-info-item">
              <WellInfoText
                label={PERMIT_INFO_HEADER_ATTRIBUTES.BOTTOM_LONGTITUDE.label}
                value={checkFormatValidation(
                  truncateStringDecimal(
                    data[PERMIT_INFO_HEADER_ATTRIBUTES.BOTTOM_LONGTITUDE.key],
                    6
                  )
                )}
              />
            </div>

            <div className="well-info-item">
              <WellInfoText
                label={PERMIT_INFO_HEADER_ATTRIBUTES.PROPOSED_DEPTH.label}
                value={`${checkFormatValidation(
                  data[PERMIT_INFO_HEADER_ATTRIBUTES.PROPOSED_DEPTH.key]
                )} ${getAttributeUnit(attributes.PERMIT_TOTAL_DEPTH.key)}`}
              />
            </div>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionSectionCard;
