import { DataGridPremium } from "@mui/x-data-grid-premium";

import { formattedNumber } from "../../../../utils/helper";

import NoResultDisplay from "../../../DataGrid/CustomGridComponents/NoResultDisplay";

const PanelTable = ({
  TableData,
  TableHeader,
  tabContentTableSize,
  isLoading,
}) => {
  let rows = [];

  if (TableData) {
    TableData.forEach((a, i) => {
      // exception checking for this field as this is
      // returns a string instead of number
      if (a.casingWeight) {
        a.casingWeight = formattedNumber(a.casingWeight);
      }
      rows.push({ ...a, id: i });
    });
  }

  return (
    <div style={{ height: tabContentTableSize, width: "100%" }}>
      <DataGridPremium
        className="dataTableWellCard"
        rows={isLoading ? [] : rows}
        columns={TableHeader}
        pageSize={5}
        rowHeight={26}
        rowsPerPageOptions={[TableData ? TableData.length : 0]}
        disableColumnMenu
        disableColumnFilter
        disableColumnSelector
        hideFooterPagination={true}
        hideFooterSelectedRowCount={true}
        slots={{ noRowsOverlay: NoResultDisplay }}
        loading={isLoading}
        columnHeaderHeight={35}
      />
    </div>
  );
};

export default PanelTable;
