import { FC, SyntheticEvent } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

import {
  ReleaseVersionsProps,
  ReleaseVersionsTypes,
} from "../../../../types/information/information";

import {
  AccordionContent,
  AccordionHeadingText,
  AccordionImage,
  AccordionSubContent,
  CustomTypography,
} from "../StyledAccordions";

import SharedSearchLink from "../../../../assets/images/release-notes/SharedSearchLink.png";
import WellPanelFromGrid from "../../../../assets/images/release-notes/WellPanelFromGrid.png";

const Version20240930: FC<ReleaseVersionsProps> = ({ expanded, onChange }) => {
  const handleChange =
    (panel: ReleaseVersionsTypes) =>
    (_: SyntheticEvent, isExpanded: boolean) => {
      onChange(isExpanded ? panel : "");
    };

  return (
    <Accordion
      expanded={expanded === "Version20240930"}
      onChange={handleChange("Version20240930")}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <AccordionHeadingText sx={{ width: "80%", flexShrink: 0 }}>
          Version 2024.09.30
        </AccordionHeadingText>
        <AccordionHeadingText sx={{ color: "rgba(255, 255, 255, 0.70)" }}>
          09/30/2024
        </AccordionHeadingText>
      </AccordionSummary>
      <AccordionDetails>
        <CustomTypography>
          This release includes sharing of dashboards and enhancements to the
          well panel functionality.
        </CustomTypography>
      </AccordionDetails>
      <AccordionContent>• Sharing Dashboard or Saved Searches</AccordionContent>
      <AccordionSubContent>
        • Users can now share their projects with others while preserving all
        features, including search queries, filtered grids, map settings, and
        plots.
      </AccordionSubContent>
      <AccordionSubContent>
        • The Share option is available on the left sidebar of the application
        by clicking Copy Link.
      </AccordionSubContent>
      <AccordionSubContent>
        • Additionally, users can share Saved Searches using the share icon
        located next to each saved search in the list.
      </AccordionSubContent>

      <AccordionImage src={SharedSearchLink} width="100%" draggable={false} />

      <AccordionContent>
        • Opening Well Panel from the Grid Table
      </AccordionContent>
      <AccordionSubContent>
        • Users can now select a well directly from the grid table to open and
        review the well panel, rather than being limited to accessing the well
        panel from the map.
      </AccordionSubContent>

      <AccordionImage src={WellPanelFromGrid} width="100%" draggable={false} />

      <AccordionContent>• Haynesville Well Economics Data</AccordionContent>
      <AccordionSubContent>
        • The recently added Haynesville well economics data are now available
        in Well Data Analytics.
      </AccordionSubContent>
    </Accordion>
  );
};

export default Version20240930;
