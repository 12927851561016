import { useMemo } from "react";

import { Badge } from "@mui/material";

import classNames from "classnames";

import usePanelsStore from "../../store/panels/panelsStore";

import useLeftNavPanel from "../../customHooks/panels/useLeftNavPanel";

import WellPanelCollapseIcon from "../common/icons/WellPanelCollapseIcon";
import WellPanelContractIcon from "../common/icons/WellPanelContractIcon";
import SidebarWellPanelSlot from "./SidebarWellPanelSlot";

const SidebarWellPanelNav = () => {
  const isWellPanelNavCollapsed = usePanelsStore(
    (state) => state.isWellPanelNavCollapsed
  );
  const toggleIsWellPanelNavCollapsed = usePanelsStore(
    (state) => state.toggleIsWellPanelNavCollapsed
  );

  const toggleNavigation = () => {
    toggleIsWellPanelNavCollapsed(!isWellPanelNavCollapsed);
  };

  const { wellsAndPermitPanel, sortedWellsAndPermitPanel } = useLeftNavPanel();

  const getWellPanelNavigation = useMemo(() => {
    const children: JSX.Element[] = [];

    sortedWellsAndPermitPanel.forEach((wellPermitData, index) => {
      children.push(
        <SidebarWellPanelSlot
          key={index + "_" + wellPermitData.wellHeaderPermitKey}
          wellPermitData={wellPermitData}
        />
      );
    });

    return children;
  }, [
    sortedWellsAndPermitPanel,
    sortedWellsAndPermitPanel.length,
    isWellPanelNavCollapsed,
  ]);

  return (
    <div className="well-panel-menu">
      <div
        className={classNames("well-panel-badge", {
          collapsed: !isWellPanelNavCollapsed,
        })}
        role="presentation"
        onClick={toggleNavigation}
      >
        <Badge color="error" badgeContent={`${wellsAndPermitPanel.length}`}>
          {isWellPanelNavCollapsed ? (
            <WellPanelCollapseIcon state="pressed" />
          ) : (
            <WellPanelContractIcon state="enabled" />
          )}
        </Badge>
        <div className="well-panel-badge-text well-panel-side-text">
          WELL PANEL
        </div>
      </div>

      {isWellPanelNavCollapsed && wellsAndPermitPanel.length && (
        <>{getWellPanelNavigation}</>
      )}
    </div>
  );
};

export default SidebarWellPanelNav;
