import usePanelsStore from "../../store/panels/panelsStore";

import useSideBarNavPanel from "../../customHooks/panels/useSideBarNavPanel";

import DashboardChartsPanel from "./dashboardChartsPanel/DashboardChartsPanel";
import ExportPackageManager from "./exportManager/ExportPackageManager";
import SavedSearchPanel from "./savedSearchPanel/SavedSearchPanel";
import SearchPanel from "./searchPanel/SearchPanel";
import WellPanelDrawer from "./wellPanel/WellPanelDrawer";

const SidebarNavPanel = () => {
  const toggleLeftSidePanel = usePanelsStore(
    (state) => state.toggleLeftSidePanel
  );

  const { openSidebarPanel } = useSideBarNavPanel();

  return (
    <>
      <DashboardChartsPanel
        open={openSidebarPanel.dashboardPanel}
        handleCloseMenuClick={toggleLeftSidePanel}
      />
      <SearchPanel
        open={openSidebarPanel.searchPanel}
        handleCloseMenuClick={toggleLeftSidePanel}
      />
      <SavedSearchPanel
        open={openSidebarPanel.savedSearchPanel}
        handleCloseMenuClick={toggleLeftSidePanel}
      />
      <WellPanelDrawer
        isOpenWellPanel={openSidebarPanel.wellPanel}
        handleCloseMenuClick={toggleLeftSidePanel}
      />
      <ExportPackageManager />
    </>
  );
};

export default SidebarNavPanel;
