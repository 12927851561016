import { useEffect, useState } from "react";

import { GunBarrelInfo } from "../../../../types/panels/wellPanel/gunBarrelType";
import {
  MapLayer,
  WellPanelSectionType,
  WellSystemData,
} from "../../../../types/panels/wellPanel/wellPanel";

import { WELL_PANEL_SECTION } from "../../../../constants/panels/wellPanel/wellInfo";

import usePanelsStore from "../../../../store/panels/panelsStore";

import useWellPanelGunBarrel from "../../../../customHooks/panels/wellPanel/useWellPanelGunBarrel";

import GunBarrelTab from "../../../charts/chartType/GunBarrelTab";
import WellPanelAccordion from "../common/WellPanelAccordion";

interface GunBarrelProps {
  uwi: string;
  chartExport: boolean;
  wellSystem: WellSystemData;
  activePage: number;
  layer: MapLayer;
  groupedWellID: number;
  isExpanded: boolean;
  onChange: (type: WellPanelSectionType) => void;
}

const GunBarrel = ({
  uwi,
  chartExport,
  wellSystem,
  activePage,
  groupedWellID,
  layer,
  isExpanded,
  onChange,
}: GunBarrelProps) => {
  const [gunBarrelData, setGunBarrelData] = useState<GunBarrelInfo | undefined>(
    undefined
  );
  const { gunBarrel, success, getWellGunBarrel } = useWellPanelGunBarrel();
  const [isLoadingGunBarrel, setIsLoadingGunBarrel] = useState(true);

  const updateGunBarrelLoaded = usePanelsStore(
    (state) => state.updateGunBarrelLoaded
  );

  const updateWellCardDataByTypeAndKey = usePanelsStore(
    (state) => state.updateWellCardDataByTypeAndKey
  );

  useEffect(() => {
    if (activePage !== wellSystem.pageNumber || wellSystem.isGunBarrelLoaded)
      return;
    if (activePage === wellSystem.pageNumber && !wellSystem.isGunBarrelLoaded) {
      if (!wellSystem.gunBarrelData) {
        setIsLoadingGunBarrel(true);
        getWellGunBarrel(wellSystem.wellId);
      } else {
        setGunBarrelData(wellSystem.gunBarrelData);
      }
    }
  }, [activePage, wellSystem.gunBarrelData, wellSystem.pageNumber]);

  useEffect(() => {
    if (gunBarrel && success) {
      setGunBarrelData(gunBarrel);
      updateWellCardDataByTypeAndKey(
        { type: "gunbarrel", gunBarrel: gunBarrel },
        wellSystem.wellId,
        layer,
        groupedWellID
      );
      updateGunBarrelLoaded(wellSystem, wellSystem.wellId);
      setIsLoadingGunBarrel(false);
    } else {
      setIsLoadingGunBarrel(false);
    }
  }, [gunBarrel, success]);

  const handleOnChange = () => {
    onChange(WELL_PANEL_SECTION.GUN_BARREL_SECTION);
  };

  return (
    <WellPanelAccordion
      expanded={isExpanded && !isLoadingGunBarrel}
      header={WELL_PANEL_SECTION.GUN_BARREL_SECTION.displayLabel}
      onChange={handleOnChange}
      isLoading={isLoadingGunBarrel}
      className="chart"
    >
      {gunBarrelData && success && (
        <GunBarrelTab
          gunBarrelData={gunBarrelData}
          uwiNumber={uwi}
          chartExport={chartExport}
        />
      )}
    </WellPanelAccordion>
  );
};

export default GunBarrel;
