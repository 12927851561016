import { useState } from "react";

import { Drawer, IconButton } from "@mui/material";

import classNames from "classnames";

import { SIDEBAR_MENU, SIDEBAR_MENU_KEYS } from "../../constants/panels/menu";
import { SHARE_SEARCH_RIGHT_MENU_PLACEMENT } from "../../constants/panels/shareLink";

import useExportCardStore from "../../store/exportCard/exportCardStore";
import usePanelsStore from "../../store/panels/panelsStore";

import useLeftNavPanel from "../../customHooks/panels/useLeftNavPanel";
import useSearchPanel from "../../customHooks/search/useSearchPanel";

import Loading from "../common/Loading";
import SidebarWellPanelNav from "./SidebarWellPanelNav";
import PanelControls from "./common/PanelControl";
import ShareSearchMenu from "./common/ShareSearchMenu";

const LeftNavigationPanel = () => {
  const [panelRef, setPanelRef] = useState<HTMLDivElement | null>(null);

  const isOpenAppLevelNav = usePanelsStore((state) => state.isOpenAppLevelNav);
  const activePanel = usePanelsStore((state) => state.activePanel);
  const isOpenLeftSidePanel = usePanelsStore(
    (state) => state.isOpenLeftSidePanel
  );
  const toggleLeftSidePanel = usePanelsStore(
    (state) => state.toggleLeftSidePanel
  );

  const exportManagerLoading = useExportCardStore(
    (state) => state.exportManagerLoading
  );

  const {
    shareSearchAnchorEl,
    openShareSearchMenu,
    wellsAndPermitPanel,
    setShareSearchAnchorEl,
    handleMenuClick,
    handleOnMouseOut,
    handleOnMouseOver,
    handleOnMouseEnter,
    getIsDisabledMenu,
  } = useLeftNavPanel();

  const { searchTypeByActiveTab, isFilterIncluded } = useSearchPanel();

  return (
    <>
      <Drawer
        ref={setPanelRef}
        anchor="left"
        open={isOpenAppLevelNav}
        className="sidebar-drawer-style"
        disableEnforceFocus
        id="app-level-navigation-drawer"
        hideBackdrop
        onMouseEnter={handleOnMouseEnter}
        onMouseOver={handleOnMouseOver}
        onMouseOut={handleOnMouseOut}
        PaperProps={{
          className: "sidebar-drawer-style-paper default-width",
        }}
      >
        <div className="sidebar-menu">
          {SIDEBAR_MENU.map((menu, index) => {
            return (
              <div
                key={menu.key}
                className="menu-container"
                onClick={(e) => handleMenuClick(menu.key, e)}
                role="presentation"
              >
                <IconButton
                  sx={{
                    ...menu.style,
                  }}
                  className={classNames("menu-icon-box", {
                    "active-menu": activePanel === menu.key,
                    "share-active-button": Boolean(
                      shareSearchAnchorEl &&
                        menu.key === SIDEBAR_MENU_KEYS.SHARE_SEARCH
                    ),
                  })}
                  disabled={getIsDisabledMenu(menu.key)}
                >
                  {menu.key === SIDEBAR_MENU_KEYS.EXPORT_PACKAGE_MANAGER &&
                  exportManagerLoading ? (
                    <Loading size={20} />
                  ) : (
                    menu.icon
                  )}
                </IconButton>

                <div
                  className={classNames("side-bar-menu-text", {
                    "disabled-menu-text": getIsDisabledMenu(menu.key),
                    "active-menu-text": Boolean(activePanel === menu.key),
                  })}
                >
                  {menu.text}
                </div>
              </div>
            );
          })}
          {wellsAndPermitPanel.length !== 0 && <SidebarWellPanelNav />}
        </div>
        {!isOpenLeftSidePanel && (
          <PanelControls
            anchorEl={panelRef}
            open={isOpenLeftSidePanel}
            clickCallback={() => {
              toggleLeftSidePanel();
            }}
          />
        )}
      </Drawer>

      <ShareSearchMenu
        open={openShareSearchMenu}
        anchorEl={shareSearchAnchorEl}
        handleClose={() => setShareSearchAnchorEl(null)}
        searchType={searchTypeByActiveTab}
        isFilterIncluded={isFilterIncluded}
        placement={SHARE_SEARCH_RIGHT_MENU_PLACEMENT}
      />
    </>
  );
};

export default LeftNavigationPanel;
