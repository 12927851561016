/**
 * This helper util will be the ts version of the helper.js
 * Move the utility used in helper.js to this helper2.ts
 */
import { noData } from "../constants/constants";

export const containsAtLeastOne = (array: any[]) => {
  return array && array.length > 0;
};

export const getTruncatedWellStatus = (wellStatus: string) => {
  const acronymIndex = wellStatus.indexOf("(");
  let acronym = "";
  let truncWellStatus = wellStatus;
  if (acronymIndex > -1) {
    truncWellStatus = wellStatus.slice(0, acronymIndex - 1);
    acronym = wellStatus.slice(acronymIndex - 1, wellStatus.length);
  }

  if (truncWellStatus.length > 12) {
    truncWellStatus = truncWellStatus.slice(0, 12) + "..." + acronym;
  } else {
    truncWellStatus = wellStatus;
  }
  return truncWellStatus;
};

export const truncateStringDecimal = (value: any, decimalPlace: number) => {
  let val = value;
  if (typeof value !== "string" && typeof value !== "number") return noData;
  if (typeof value === "number") val = value.toString();

  const values = val.split(".");
  if (values[1]) {
    return `${values[0]}.${values[1].substring(0, decimalPlace)}`;
  }

  return val;
};

export const capitalizeFirstLetterString = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
